<template>
    <div class="row">
        <div class="col">

            <div class="card bd-1 bd-white-1 pd-10 mg-b-20">
                <div class="card-header bg-transparent pd-0 bd-b-0">
                    <h5 class="tx-14 tx-uppercase tx-spacing-1 tx-semibold">Filter Calls</h5>
                </div><!-- card-header -->
                <div class="card-block pd-0">

                    <div class="row row-sm mg-t-10">

                        <div class="col-3 mg-t-10 mg-lg-t-0">
                            <div class="form-group mg-b-0">
                                <label>Start Date</label>
                                <input type="date" v-model="filters.start_date" class="form-control" placeholder="DD/MM/YYYY">
                            </div><!-- form-group -->
                        </div><!-- col-2 -->
                        <div class="col-3 mg-t-10 mg-lg-t-0">
                            <div class="form-group mg-b-0">
                                <label>End Date</label>
                                <input type="date" v-model="filters.end_date" class="form-control" placeholder="DD/MM/YYYY">
                            </div><!-- form-group -->
                        </div><!-- col-2 -->
                        <div class="col-3 mg-t-10 mg-lg-t-0">
                            <div class="form-group">
                                <label>Processor</label>
                                <select v-model="filters.user" class="form-control form-control-dark select2" v-select2>
                                    <option v-for="user in processors" v-bind:value="user.id">{{user.email}}</option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <a href="javascript:void(0);" @click="getCalls(1)" class="btn btn-info tx-11 tx-spacing-1 tx-uppercase tx-semibold tx-mont pd-y-12 pd-x-30 mg-t-10">Search</a>

                    <a href="javascript:void(0);" @click="clearFilters" class="btn btn-primary tx-11 tx-spacing-1 tx-uppercase tx-semibold tx-mont pd-y-12 pd-x-30 mg-t-10 mg-l-10">Clear Filters</a>

                </div>
            </div>

            <pagination :data="pagination" @search="getCalls"></pagination>
        
            <table class="table">
                <thead class="thead-colored thead-primary">
                    <tr>
                        <th>Datetime</th>
                        <th>User</th>
                        <th>To</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Duration</th>
                        <th>Status</th>
                        <th>Sites / Claims</th>
                        <th>Notes</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="call in calls">
                        <td>{{ call.created_at }}</td>
                        <td>{{ call.user_email }}</td>
                        <td>{{ call.recipient }}</td>
                        <td>{{ call.start }}</td>
                        <td>{{ call.end }}</td>
                        <td>{{ call.duration }}</td>
                        <td>{{ call.status }}</td>
                        <td>
                            <ul v-if="call.sites?.length > 0">
                                <li v-for="site in call.sites">({{site.reference}}) {{site.name}}</li>
                            </ul>
                            <ul v-if="call.claims?.length > 0">
                                <li v-for="claim in call.claims">{{claim}}</li>
                            </ul>
                        </td>
                        <td>{{ call.notes }}</td>
                        <td>
                            <a v-if="call.sas" :href="call.sas" target="_blank" class="btn btn-primary btn-sm">Listen</a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <pagination :data="pagination" @search="getCalls"></pagination>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import {DateTime} from 'luxon';
import pagination from '@/components/Pagination.vue';

export default {
    name: "Calls",
    components: {
        pagination
    },
    data(){
        return{
            calls: [],
            pagination: {
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 0,
                lastPage: 1,
            },
            filters: {
                start_date: '',
                end_date: '',
                user: null
            },
            processors: []
        }
    },
    mounted(){
        this.getCalls();
        this.loadClaimProcessors();
    },
    methods: {
        loadClaimProcessors(){
            if(!this.$can('claims-process')){
                return;
            }
            axios.get(`https://server.varsanpr.com/api/users/processors`, {
                headers: authHeader()
            })
            .then(response => {
                response.data.users = response.data.users.sort((a, b) => {
                    return a.email.localeCompare(b.email);
                });
                this.processors = response.data.users;
            })
            .catch(error => {
                this.$error("Failed loading processors!", error);
            });
        },
        clearFilters(){
            this.filters.start_date = '';
            this.filters.end_date = '';
            this.filters.user = null;
        },  
        getCalls(page = 1){
            let params = {page: page};
            if(this.filters.start_date){
                try{
                    params.start = DateTime.fromFormat(this.filters.start_date, 'yyyy-MM-dd').toSeconds();
                }catch(err){

                }
            }
            if(this.filters.end_date){
                try{
                    params.end = DateTime.fromFormat(this.filters.end_date, 'yyyy-MM-dd').toSeconds();
                }catch(err){

                }
            }
            if(this.filters.user){
                params.user_id = this.filters.user;
            }
            axios.get('https://api.varsanpr.com/api/calls/recordings', { headers: authHeader(), params: params })
            .then(response => {
                this.calls = response.data.calls;
                this.calls.forEach((call) => {
                    call.created_at = DateTime.fromSeconds(call.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                    if(call.start){
                        call.start = new Date(call.start).toLocaleTimeString();
                    }
                    if(call.end){
                        call.end = new Date(call.end).toLocaleTimeString();
                    }
                })
                this.pagination.currPage = response.data.pagination.page;
                this.pagination.totalResults = response.data.pagination.total;
                this.pagination.from = 1 + (response.data.pagination.page -1) * response.data.pagination.showing;
                this.pagination.to = 1 + response.data.pagination.page * response.data.pagination.showing;
                if(this.pagination.to > this.pagination.totalResults){
                    this.pagination.to = this.pagination.totalResults;
                }
                this.pagination.perPage = response.data.pagination.showing;
                this.pagination.lastPage = response.data.pagination.lastPage;
            })
            .catch(e => {
                console.log(e);
            });
        },
    }
}
</script>